import React, { useContext, useEffect } from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography, Divider } from "@material-ui/core"

// Core components
import { useGlobalState } from "hooks/useGlobalState"

// Other components
import CopyToClipboardBtn from 'components/CopyToClipboardBtn/CopyToClipboardBtn'

// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import AdminPanels from "components/AdminPanels/AdminPanels";
import { Link } from "react-router-dom";

import axios from "axios";
import { generateCode } from 'helpers/generateCode'
import {getDomain} from "helpers/getDomain";
import HighlightIntegrationCode from "components/HighlightIntegrationCode";
const useStyles = makeStyles(styles)

const ConfirmedStep = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const { theRef } = props

  /* eslint-disable */
  useEffect(() => {
    // update the state with new integration data
    axios.post(
      process.env.REACT_APP_API_URL + 'user/get',
      { 
        cukey:  state?.adminMode?.cuid || state.signedInUser.cukey,
        customerEmail: state?.adminMode?.cuemail 
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
    ).then(response => {
      if (response && response.data && response.status === 200) {
        dispatchState({
          type: 'root-state',
          payload: {
            signedInUser: {
              ...state.signedInUser, data: response.data
            }
          }
        });
      }
    }).catch(error => {
      console.error(error);

    })

    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 6 } }) // Set stepper completed
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */
  const selectedIntegration = state.integrationDataOptions?.selectedIntegration ? state.integrationDataOptions.selectedIntegration : 0
  // This strange formatting is required as whitespace inside the template literal
  // is whitespace in the output string
  const generatedCode = generateCode(state, selectedIntegration);

  return (
    <form noValidate autoComplete="off" className={classes.standardForm}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">Confirmation</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3">Integration has been published</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Ask your developer to copy and paste this code on the webpage where you want the calculator to be displayed.</Typography>
          <HighlightIntegrationCode
            integration={getDomain(state.signedInUser?.data?.user?.integrations?.[selectedIntegration]?.domain || '')}
            integration2={getDomain(state.signedInUser?.data?.user?.integrations?.[selectedIntegration]?.domain2 || '')}
            clientRef={state?.adminMode?.cuid || state.signedInUser.cukey}
            int={state.signedInUser?.data?.user?.integrations?.[selectedIntegration]?.id}
          />
          <CopyToClipboardBtn txtcopy={generatedCode} />
        </Grid>
        <Grid item xs={12}>
          <AdminPanels
            theHeader={false}
            headerAction={false}
            fabCodeAction={false}
            fabCloneAction={false}
            fabEditAction={false}
            fabDeleteAction={false}
            panelTheme="dark"
          >
            <Typography variant="h5">Need assistance?</Typography>
            <Typography variant="body1">Please <Link to="/admin/support">contact us</Link> if you have questions or display issues and your developer can't fix it quickly. We'll help to fix it (usually free of charge).</Typography>
          </AdminPanels>
        </Grid>
      </Grid>

    </form>
  )
}

export default ConfirmedStep
