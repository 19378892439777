import React from "react";
import { makeStyles,  } from '@material-ui/core';

export default ({
  integration,
  integration2,
  int,
  clientRef
}) => {
  const classes = useStyles();
  return (
    <div className={classes.hljs}>
      &lt;
      <span className="hljs-keyword">
        <span className="hljs-keyword">
          <span className="hljs-keyword">div</span>
        </span>
      </span>
      {"\n"}{"  "}
      <span className="hljs-built_in">
        <span className="hljs-built_in">
          <span className="hljs-built_in">id</span>
        </span>
      </span>
      =
      <span className="hljs-string">
        <span className="hljs-string">
          <span className="hljs-string">"calcumate-root"</span>
        </span>
      </span>
      {"\n"}{"  "}
      data-integration=
      <span className="hljs-string">
        <span className="hljs-string">
          <span className="hljs-string">"{integration}"</span>
        </span>
      </span>
      {"\n"}{"  "}
      data-integration
      <span className="hljs-number">
        <span className="hljs-number">
          <span className="hljs-number">-2</span>
        </span>
      </span>
      =
      <span className="hljs-string">
        <span className="hljs-string">
          <span className="hljs-string">"{integration2}"</span>
        </span>
      </span>
      {"\n"}{"  "}
      data-
      <span className="hljs-keyword">
        <span className="hljs-keyword">
          <span className="hljs-keyword">ref</span>
        </span>
      </span>
      =
      <span className="hljs-string">
        <span className="hljs-string">
          <span className="hljs-string">
            "{clientRef}"
          </span>
        </span>
      </span>
      {"\n"}{"  "}
      data-int=
      <span className="hljs-string">
        <span className="hljs-string">
          <span className="hljs-string">
            "{int}"
          </span>
        </span>
      </span>
      {"\n"}
      &gt;&lt;/
      <span className="hljs-keyword">
        <span className="hljs-keyword">
          <span className="hljs-keyword">div</span>
        </span>
      </span>
      &gt;
      {"\n"}
      &lt;
      <span className="hljs-keyword">
        <span className="hljs-keyword">
          <span className="hljs-keyword">script</span>
        </span>
      </span>{" "}
      defer src=
      <span className="hljs-string">
        <span className="hljs-string">
          <span className="hljs-string">
            "{process.env.REACT_APP_CALCULATOR_SCRIPT_URL}"
          </span>
        </span>
      </span>
      &gt;&lt;/
      <span className="hljs-keyword">
        <span className="hljs-keyword">
          <span className="hljs-keyword">script</span>
        </span>
      </span>
      &gt;
    </div>
  );
};

// ============================================================================
// Styles
// ============================================================================
//Styles and classes taken from react-highlight.js

const styles = {
  hljs: {
    display: 'block',
    overflowX: 'auto',
    padding: '1rem',
    borderRadius: 6,
    color: '#333',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    whiteSpace: 'pre-wrap',
    fontFamily: 'monospace',
    margin: '1em 0px',
    '& .hljs-keyword': {
      color: '#333',
      fontWeight: '700',
    },
    '& .hljs-built_in': {
      color: '#0086b3',
    },
    '& .hljs-string': {
      color: '#d14',
    },
    '& .hljs-number': {
      color: 'teal',
    }
  },
};

const useStyles = makeStyles(styles);
