import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Tooltip,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// core components
import classNames from "classnames";

// Other components
import PlanButton from "./components/PlanButton";

// Style
import styles from "assets/jss/material-dashboard-react/components/CustomPricingStyle";
import usePrice from "../../../hooks/usePrice";
import { round, pricingPlanFeature } from "../../../helpers/common";
import Currencies from "../Currencies";
import CustomPricingSwitch from "./CustomPricingSwitch";
import Counter from "views/common/Counter";
import { useGlobalState } from "hooks/useGlobalState";

const useStyles = makeStyles(styles);

const CustomPricingPanel = (props) => {
  const classes = useStyles();
  let state = useContext(useGlobalState.State);
  const screenSize = useRef();
  const dispatchState = useContext(useGlobalState.Dispatch);
  const [serviceNumber, setServiceNumber] = useState(state.serviceNumber);
  const [defaultServiceNumber] = useState(state.serviceNumber);
  const [defaultService] = useState(state.service);

  const {
    index,
    thePlan,
    theFeatureList,
    theDisabledFeatureList,
    theFeatureListPricing,
    popular,
    showSign,
    service,
    thePlanLabel,
    classes: customClasses,
    showCounter,
    isRecommended,
  } = props;

  const finalPrice = usePrice({
    plan: props,
    currency: state.currency,
    type: state.cmPlanPayRec,
    number: serviceNumber,
  });

  const finalPricePretty = useMemo(() => {
    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
    const sign = showSign ? currencyDetails.sign : '';
    return `${sign}${round(finalPrice)}`
  }, [
    state.currency, 
    state.cmPlanPayRec, 
    serviceNumber,
    props,
    service,
  ]);

  const slittedPrice = useMemo(() => {
    const currencyDetails = state.availableCurrencies.find(
      (currency) => currency.currency === state.currency
    );
    const sign = showSign ? currencyDetails.sign : "";
    if (typeof finalPrice === "number") {
      const pr = finalPrice / serviceNumber;
      const value = state.cmPlanPayRec ? pr / 12 : finalPrice / serviceNumber;
      const prefix = <span className={classes.pricePrefix}>{state.currency}{state.currentPlanPriceRec}</span>;
      return (
        <>
          <div style={{display: 'flex',}}>
            <span>{sign}{round(Number.isNaN(value) ? '0' : value)}</span>
            <span className={classNames('vertical-details', classes.pricePrefix)}>
              <span>{props.unitLabel} /</span>
              <span>{state.cmPlanPayRec ? 'Month^' : 'Month'}</span>
            </span>
          </div>
          <div style={{fontSize: 25}}>Total {finalPricePretty}{prefix}</div>
        </>
      );
    }
    return "";
  }, [
    state.currency,
    state.cmPlanPayRec,
    serviceNumber,
    props.unitLabel,
    service,
  ]);

  useEffect(() => {
      window.addEventListener("resize", () => {
          screenSize.current = window.innerWidth;
      });
      return () => {
          window.removeEventListener("resize", () => {
              screenSize.current = window.innerWidth;
          })
      }
  }, []);

  const calculateHeight = useCallback((elemIndex) => {
    const plans = document.querySelectorAll('.pricing-plan-card');
    if (!plans.length) return 'auto';
    const heightMatrix = [
      plans[0]?.querySelector('.features-list')?.childNodes?.[elemIndex]?.scrollHeight || 0,
      plans[1]?.querySelector('.features-list')?.childNodes?.[elemIndex]?.scrollHeight || 0,
      plans[2]?.querySelector('.features-list')?.childNodes?.[elemIndex]?.scrollHeight
       || 0,
    ].sort(function(a, b){return b - a});

    return heightMatrix[0];
  }, [
    theFeatureList,
    theDisabledFeatureList,
    screenSize?.current
  ]);

  useEffect(() => {
    dispatchState({
      type: "root-state",
      payload: {
        serviceNumber,
      },
    });
  }, [serviceNumber]);
  return (
    <Grid
      style={{position: 'relative', width: '100%', maxWidth: 400, height: '100%'}}
      id="scroll-point"
      className={classNames({
        [classes.popularPlanWrap]: popular
      })}
    >
      {popular && <Grid className={classes.popularOne}>best value</Grid>}
      <Paper
        key={index}
        elevation={2}
        className={classNames(
          classes.pricingPaper,
          "pricing-plan-card",
          customClasses,
          {
            [classes.isPopular]: popular
          }
        )}
        style={
          popular ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 } : {}
        }
      >
        <Grid className={classNames(classes.top, {
          [classes.paid]: showCounter
        })}>
          <Typography className={classNames("plan-name", classes.planName)}>
            {thePlanLabel || thePlan}
          </Typography>
          {theFeatureListPricing.map((item, key) => {
            return (
              <p
                key={key}
                className={classNames(classes.mainFeature, {
                  [classes.contactUsMainFeature]: thePlan === 'Contact-us'
                })}
                dangerouslySetInnerHTML={{ __html: pricingPlanFeature(item) }}
              />
            );
          })}
          {showCounter && (
            <Grid className={classes.freeTrial}>
              FIRST 30 DAYS FREE and then
            </Grid>
          )}
          {slittedPrice && (
            <p className={
              classNames(
                'plan-price', 
                'splitted-price', 
                {
                  [classes.paidPlan]: showCounter,
                  ['plan-price-yearly']: state.cmPlanPayRec,
                })} 
                data-cy={"price"}
              >
              {slittedPrice}
            </p>
          )}
          {props.showCounter && (
            <div
              className={classNames(classes.counterWrap, 'facility-select')}
            >
              <Counter
                number={serviceNumber}
                setNumber={(val) => setServiceNumber(val)}
              />
            </div>
          )}
          {showCounter && (
            <Grid className={classes.pricingModeWrap}>
              <div className={classes.planSwitcherDesktop}>
                <CustomPricingSwitch />
                <Currencies />
              </div>
            </Grid>
          )}
        </Grid>
        <PlanButton
          plan={props}
          type={index % 2 ? 'filled' : 'outlined'}
          state={state}
          defaultServiceNumber={defaultServiceNumber}
          defaultService={defaultService}
        />
        <Grid className={classNames(classes.middle, {
          [classes.middleCustomPlan]: thePlan === 'Contact-us'
        })}>
          <List
            dense
            style={{paddingBottom: 0}}
            className={classNames(classes.priceList, "features-list")}
          >
            {theFeatureList.map((prop, key) => {
              return (
                <ListItem className={classes.planFeatureElem} style={{height: calculateHeight(key)}} key={key} disableGutters>
                  <ListItemIcon className="feature-icon">
                    <CheckIcon
                      fontSize="small"
                      className={classes.checkMarkIcon}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.featureListItemText}
                    variant="caption"
                    id={`price-list-${key}`}
                  >
                    <Tooltip 
                      title={<span style={{fontSize: 15}}>{prop.tooltip}</span>}
                      disableHoverListener={!prop.tooltip}
                      disableFocusListener={!prop.tooltip}
                    >
                      <span dangerouslySetInnerHTML={{ __html: pricingPlanFeature(prop) }} />
                    </Tooltip>
                  </ListItemText>
                </ListItem>
              );
            })}
            {(theDisabledFeatureList || []).map((prop, key) => {
                return (
                  <ListItem style={{height: calculateHeight(theFeatureList.length + key)}} className={classes.planFeatureElem} key={key} disableGutters>
                    <ListItemIcon className={classNames("feature-icon")}>
                      <ClearIcon
                        fontSize="small"
                        className={classes.clearIcon}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classNames(
                        classes.featureListItemText,
                        classes.disabled
                      )}
                      variant="caption"
                      id={`price-list-${key}`}
                    >
                      <Tooltip 
                        title={<span style={{fontSize: 15}}>{prop.tooltip}</span>}
                        disableHoverListener={!prop.tooltip}
                        disableFocusListener={!prop.tooltip}
                      >
                        <span dangerouslySetInnerHTML={{ __html: pricingPlanFeature(prop) }} />
                      </Tooltip>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          {/* {theDisabledFeatureList && (
            <List
              dense
              style={{marginTop: 0,}}
              className={classNames(
                classes.priceList,
                "features-list",
                "disabled-features-list"
              )}
            >
              {theDisabledFeatureList.map((prop, key) => {
                return (
                  <ListItem key={key} disableGutters>
                    <ListItemIcon className={classNames("feature-icon")}>
                      <ClearIcon
                        fontSize="small"
                        className={classes.clearIcon}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classNames(
                        classes.featureListItemText,
                        classes.disabled
                      )}
                      variant="caption"
                      id={`price-list-${key}`}
                    >
                      <span dangerouslySetInnerHTML={{ __html: prop }} />
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List> 
          )} */}
        </Grid>
        <div className={classNames(classes.planFooter, "pricing-submit-button")}>
          <Grid container>
            <Grid item xs={12}><Typography className={classes.planNameFooter}>{thePlanLabel}</Typography></Grid>
            <Grid item xs={12}>
              <PlanButton
                plan={props}
                size="middle"
                type={index % 2 ? 'filled' : 'outlined'}
                state={state}
                isSubmit={false}
                defaultServiceNumber={defaultServiceNumber}
                defaultService={defaultService}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Grid>
  );
};

export default CustomPricingPanel;
